<div class="container-fluid">
  <div class="row mainBackground">
    <div class="col-md-12 text-center">
      <img src="assets/logo.png" class="logo my-5" alt="">
    </div>
  </div>
  <div class="row py-5 questions">
    <div class="col-md-10 mx-auto" *ngIf="lang === 'english'" >
      <h2 class="mb-4">{{questions?.question}}</h2>
      <p class="mb-4" [innerHTML]="questions?.answer"></p>
    </div>
    <div class="col-md-10 mx-auto" *ngIf="lang === 'french'" >
      <h2 class="mb-4">{{questions?.french_question}}</h2>
      <p class="mb-4" [innerHTML]="questions?.french_answer"></p>
    </div>
  </div>
</div>
