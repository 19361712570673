import { FaqService } from './../../services/faq.service';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  questions: any;
  searchString: any;
  filterResult: any;
  condition = 'empty';
  mainTitle = 'All Questions';
  language = 'english';
  lang = '';
  selectBox: any;
  placeholder = "Search";
  constructor(private route: ActivatedRoute, private router: Router, private service: FaqService) { }

  ngOnInit(): void {
    // this.lang = this.router.url;
    // if(this.lang === '/?lang=fr_CA'){
    //   this.selectBox = document.getElementById('dnn_ctr6707_TimeTableView_ClassesList');
    //   this.selectBox.value = 'french';
    //   this.languageChange('french');
    // }
    this.route.queryParams
      .subscribe(params => {
        console.log(params);
        this.language = params.lang == 'fr_CA' ? 'french' : 'english';
        console.log(this.language);
      });

    this.getQuestions();
    // console.log(document.getElementById('english'));
  }

  getQuestions() {
    this.service.getquestions().subscribe((resp: any) => {
      // console.log(resp);
      this.questions = resp.data;
      console.log(this.questions);
    })
  }
  languageChange(value) {
    if (value == 'french') {
      this.language = 'french';
      this.mainTitle = 'Toutes les questions'
      this.placeholder = 'Chercher'
    }
    else {
      this.language = 'english'
      this.mainTitle = 'All Questions'
      this.placeholder = 'Search'


    }
  }
  search(input) {
    if (input.control.value === '') {
      // alert('jii')
      this.condition = 'empty';
      this.mainTitle = 'All Questions';
      this.getQuestions();
    }
    else {
      const formData = new FormData();
      this.searchString = input.control.value;
      console.log(this.searchString);
      formData.append("searchString", this.searchString);
      if (this.language == 'english') {
        formData.append("searchType", 'english');
      } else {
        formData.append("searchType", 'french');
      }

      this.service.filterquestions(formData).subscribe(
        (resp: any) => {
          if (resp.status == true) {
            this.condition = 'empty';
            this.mainTitle = `Search Results for ${this.searchString}`;
            console.log(this.condition);
            this.questions = resp.data;
            console.log('Filter Questions', resp.data);
          }
          else {
            console.log(resp.message)
            this.condition = `No results found matching ${this.searchString}`;
          }
        })
    }
  }
}
