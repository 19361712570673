<div class="container-fluid">
  <div class="header">
    <div class="topnav">
      <div class="header-logo">
        <img src="assets/logo.png" alt="" />
      </div>
      <div class="menu">
        <div class="dropdown" style="float: left;">
          <a href="/">TOUR</a>
          <div class="dropdown-content">
            <a href="https://iprosper.ca/software-financial-advisors/">Financial Advisors & Planners</a>
            <a href="https://iprosper.ca/productivity-software-placement-agency/">Placement Agencies</a>
            <a href="https://iprosper.ca/productivity-software-financial-advisory-firms/">Financial Advisory Firms</a>
            <a href="https://iprosper.ca/productivity-software-business-advisors/">Coaches and Consultants</a>
            <a href="https://iprosper.ca/support">Marketing & Creative Agencies</a>
            <a href="https://iprosper.ca/productivity-software-it-consultants/">IT management companies</a>
          </div>
        </div>
        <a href="https://iprosper.ca/pricing">PRICING</a>
        <a href="https://iprosper.ca/our-story">FOR LEADERS</a>
        <a href="https://iprosper.ca/support">SUPPORT</a>
        <a href="https://app.iprosper.ca/login?lang=en" class="login">LOGIN</a>
        <a href="https://iprosper.ca/fr">FR</a>
        <select
          class="form-select"
          (change)="languageChange($event.target.value)"
          [(ngModel)]="language"
          style="float: right;"
        >
          <option value="english">English</option>
          <option value="french">French</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row mainBackground position-relative">
    <div class="col-md-6 mx-auto">
      <h1
        *ngIf="language == 'english'"
        class="fw-bold text-white my-5 text-center"
      >
        Hi, what are you looking for?
      </h1>
      <h1
        *ngIf="language == 'french'"
        class="fw-bold text-white my-5 text-center"
      >
        Salut, tu cherches quoi?
      </h1>
      <div class="input-group mb-5">
        <span class="input-group-text" id="basic-addon1">
          <i class="fa fa-search"></i>
        </span>
        <input
          type="text"
          id="myInput"
          (keyup.enter)="search(searchKey)"
          (blur)="search(searchKey)"
          ngModel
          name="searchKey"
          #searchKey="ngModel"
          class="form-control"
          placeholder="{{ placeholder }}"
          aria-label="Username"
          aria-describedby="basic-addon1"
        />
      </div>
    </div>
  </div>
  <div class="row py-5 questions">
    <div class="col-md-10 mx-auto" *ngIf="condition == 'empty'">
      <h1 class="text-center mb-5">{{ mainTitle }}</h1>
      <div *ngIf="language == 'english'">
        <h2
          class="mb-4 h5 d-inline-flex w-100"
          *ngFor="let item of questions"
          target="_blank"
          [routerLink]="['detail', language, item.id]"
        >
          <i class="fa fa-circle"></i><span>{{ item.question }}</span>
        </h2>
      </div>
      <div *ngIf="language == 'french'">
        <h2
          class="mb-4 h5 d-inline-flex w-100"
          *ngFor="let item of questions"
          target="_blank"
          [routerLink]="['detail', language, item.id]"
        >
          <i class="fa fa-circle"></i><span>{{ item.french_question }}</span>
        </h2>
      </div>
      <!-- <h2 class="mb-4 h5 d-inline-flex w-100" *ngFor="let item of questions" target="_blank" routerLink="/detail/{{item.faqId}}"><i class="fa fa-circle"></i><span>{{item.faqQuestion}}</span></h2> -->
    </div>
    <div class="col-md-6 mx-auto" *ngIf="condition != 'empty'">
      <h2 class="mb-4">{{ condition }}</h2>
    </div>
  </div>
</div>
