import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FaqService } from './../../services/faq.service';
@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.css']
})
export class QuestionDetailComponent implements OnInit {
  id = '';
  lang = '';
  questions: any;
  constructor(private route: ActivatedRoute,private service: FaqService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.lang = this.route.snapshot.paramMap.get('lang');
    // console.log(this.id);
    // console.log(this.lang);
    this.getQuestions();
  }
   getQuestions(){
    this.service.getquestions().subscribe((resp:any) =>{
      // console.log(resp);

      for (let index = 0; index < resp.data.length; index++) {
        if(this.id == resp.data[index].id){
          console.log('hhhhhh');
          this.questions = resp.data[index];
        }
      }
      console.log(this.questions);
    })
  }
}
