import { environment } from './../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  formMode: any;

  constructor(
    private http: HttpClient,

    ) { }
    getquestions(){
      return this.http.get(`${environment.apiUrl}/public/api/getQuestions`);
      // return this.http.get(this.url);
    }

    filterquestions(body){
      // return this.http.post(this.url,body);
      return this.http.post(`${environment.apiUrl}/public/api/searchString`, body);
    }

}
